.fe-col{
    margin-left: 10%;
}

.col-centered{
    float:none;
    
}

.black-css{}

/* .mid-col{
    margin-right:400px;
} */

.center_row{
    display: flex;
    justify-content: center;
    
}

.sized_col{
    max-width: 300px;

}


@media only screen and (max-width: 900px) {
    .fe-col{
        margin-left: 0;
    }
    .sized_col{
        max-width: auto;
    
    }
}

.fd_col{
    margin-left: 60px;
}

@media only screen and (max-width: 900px) {
    .fd_col{
        margin-left: 0;
    }
}

.flip-card {
    background-color: transparent;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    background-color: transparent;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  .flip-card:hover .featuresInfo {
    display: none;
    
  } 
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: transparent ;
    color: #d9dcdf;
  }
  
  .flip-card-back {
    background-color: transparent;
   
    transform: rotateY(180deg);
  }

