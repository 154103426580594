.img_margin12{
    margin-left:20%;
}

.col_margin{
    margin-left: 15%;
}

@media only screen and (max-width: 790px) {
    .img_margin12{
        margin-left: 0;
    }
}


@media only screen and (max-width: 1200px) {
    .img_margin12{
        margin-left: 0%;
    }
}

@media only screen and (max-width: 790px) {
    .img_margin12{
        margin-left: 0;
    }
}

@media only screen and (max-width: 1300px) {
    .col_margin{
        margin-left: 5%;
    }
}

@media only screen and (max-width: 950px) {
    .col_margin{
        margin-left: 0;
    }
}