.req-btn{
    margin-top: 25px;
    border: 2px solid #b8b6b6;
    border-radius: 10px;
    /* background: #067e24; */
    font-size: 20px;
    font-weight: 400;
    padding: 5px 10px;
    color:rgb(20, 77, 20);
    font-size: 22px;

}

.req-btn:hover{
    
    transition-delay: 10ms;
    transition-timing-function: ease-in-out;
    
    background:#ffffff;
    font-weight: 400;
    border: 2px solid #067e24;
    color:rgb(20, 77, 20);
    transition: font-size 500ms, color 500ms, background 500ms;
}

.modal-hr {
    width: 100%;
    color: #dcd8d8;
}

.bbi-logo {
    display: flex;
    
    align-items: center;
    justify-content: center;
}

.poster-margin {
    margin: 5rem 0rem;
}