
p{
    margin: 0;
}
.footer-icon{
    font-size: 16px;
}

.instagram:hover{
    /* background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ); */
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
            /* Also define standard property for compatibility */
            background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

.twitter:hover{
    color :  #1DA1F2;
}

.facebook:hover{
    color :  #4267B2;
}

/* -------------- Appointment Button and form------------------------- */
.submit-btn{
    margin-top: 25px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    background: #0B4575;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 10px;
    color:#f2f2f2;

}

.submit-btn:hover{
    transition: font-weight 30ms, color 200ms, background 20ms;
    transition-delay: 10ms;
    transition-timing-function: ease-in-out;
    color:#0B4575;
    background:#f1f1f1;
    font-weight: 400;
    border: 2px solid #0B4575;
}

.form-div{
    margin-top: 20px ;
    padding-left: 10px;
}

.form-input{
    border-radius: 5px;
    width: 80%;
    border: 1px solid #0B4575;
    padding-left: 10px;
}

.form-title{
    padding-left:5px;
    font-weight:400;
    font-size:16px;
}

.form-error{
    padding-left:7px;
    color:red;
    font-size:15px;
}

.select-placeholder{
    color: #75747441;
}

