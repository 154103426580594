.custom-heading{
    color: #0B4575;
    margin-bottom: 20px;
    
}

.home-container{
    margin-top: -40px;
}

.custom-heading > span{
    border-bottom: 2px solid #0B4575;
}

@media only screen and (max-width: 1024px) {
    .container-fixed{
        margin-left: -50px;
    }
}