/* -------------- Appointment Button and form------------------------- */

.submit-btn{
    margin-top: 25px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    background: #0B4575;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 10px;
    color:#f2f2f2;

}

.submit-btn:hover{
    transition: font-weight 30ms, color 200ms, background 20ms;
    transition-delay: 10ms;
    transition-timing-function: ease-in-out;
    color:#0B4575;
    background:#f1f1f1;
    font-weight: 400;
    border: 2px solid #0B4575;
}

.form-div{
    margin-top: 20px ;
    padding-left: 10px;
}



.form-title{
    margin-bottom: 10px;
    padding-left:5px;
    font-weight:400;
    font-size:16px;
}

.form-error{
    padding-left:7px;
    color:red;
    font-size:15px;
}

.select-placeholder{
    color: #75747441;
}

