

  .vlack{
      background-color: rgb(2, 241, 150);
  }

  .col-md-6,.col-xs-6 {
      padding-right: 0px;
      /* padding-left: 0px; */
      margin: 0px;
  }
  .row{
      margin: 0;
      padding: 0;
  }
 

  .news-heading{
      display: flex;
      flex-direction: column;

  }

  .news-col{
      /* border: 1px solid rgb(221, 37, 37); */
  }

  .img-section{
      height: 100px;
      width: 100px;
  }

  .custom{
      box-sizing: border-box;
  }
  .divCol:hover {
    background-color: #0b4575;
    color: #fff;
    
  }
  

  /* .styled-border{
      border: 1px solid #0b4575;
  } */

  .column {
    margin: 0;
    border: 1px solid #fff;
    float: left;
    
  }

  .divCol{
    /* width: 70%; */
    height: 100%;
    width: 70%;
  }
  .divText-1{
      padding-left: 10px;
      height: 50px;
      /* background-color: rgb(60, 43, 216); */
  }
  .divText-2{
    padding-left: 10px;
    height: 30px;
    font-size: 12px;
    /* background-color: rgb(60, 43, 216); */
}
.divText-3{
    font-size: 12px;
    padding-left: 10px;
    bottom: 10px;
    height: 20px;
    /* background-color: rgb(60, 43, 216); */
}

.container345 {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  
  .news-text{
    border: 1px solid transparent;
    background-color: #0b4575da;
    padding: 5px;
    border-radius: 5px; 
    margin-top: 10px;
  }

  .news-text:hover {
    font-size: 16px;
    transition:  800ms;
    background-color: #0b4575e1;
    border-radius: 3px; 

}

  .news-section >span{
      /* text-decoration: underline; */
      padding: 3px;
      background-color: #0b45755b;
  }

  .img-bottom-text {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -10%);
    color: rgb(255, 255, 255);
  }
  
  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
  /* .centered-text >span {
    margin-top: 10px;
    
  } */
  
  
  .main-news-img{
      padding-left:15% ;
      padding-right:15% ;
  }

  .img-design{
    /* background-size: 75% 50%;
    background-repeat: no-repeat;
    background-position: left top; */
    object-fit: cover;
  }
  .news_span_text{
    background-color:#0b4575d5;
    padding:4px;
    border:1px solid white;
    border-radius:5px ;
  }

  .pdfLink{
    color:rgba(233, 233, 248, 0.822);
    
  }

  .pdfLink:hover{
    color: white;
  }
  .news_span_text:hover{
    border-radius:0px ;
    background-color: #0b4575;
    padding: 5px;
    transition: 350ms;
  }



@media only screen and (max-width: 400px) {
    .img-section{
        display: none;
    }
    .divCol{
        width: 100%;
    }
}

@media only screen and (max-width: 499px) {
    .main-news-img{
        padding-left:0% ;
        padding-right:0% ;
    }
}


@media only screen and (max-width: 700px) {
    .main-news-img{
        padding-left:40px ;
        padding-right:40px ;
    }
}


@media only screen and (max-width: 600px) {
  .news-text{
    background-color: transparent;
  }
  .news-text:hover{
    background-color: #fff;
    color: #0b4575;
    padding: 2px;
    /* word-spacing: 5px; */
    border-radius: 0;
  }
}