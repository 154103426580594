html {
    scroll-behavior: smooth;
  }
  
.hide-bullet{
    list-style: none;
}
.white-color{
    color: aliceblue;
}

.heading-color{
    color: #0B4575;
}
.col-fixed-height-120{
    height: 240px;
}

.pointer-cursor{
    cursor: pointer;
}
.pointer-cursor:hover{
    text-decoration: underline;
}

.cream{
    background-color:rgba(240, 213, 213, 10%);
}
.home-div{
    /* margin-top: 20px; */
    padding:20px
}
.fe-col{
    display: table;
    margin-left: 10%;
    margin-right: 10%;
}

.column{
    float: right;
    width: 18%;
    margin-right: 20px;
    
   
}

.text-border{
    line-height: 20px;
}
.text-border > span{
    border-bottom: 1px solid #fafafa;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
}
.home-container{
    padding:40px ;
}
.doctor-info{
    height: 450px;
}
.col-size-features{
    display: table-cell;
}
@media only screen and (max-width: 1200px) {
    .doctor-info{
        height: 550px;
    }
}
@media only screen and (max-width: 990px) {
    .doctor-info{
        height: auto;
    }
    
}

@media only screen and (max-width: 1310px) {
    .fe-col{
        margin-left: 50px;
        margin-right: 50px;
    }
    .column{

        width: 22%;
        margin-right: 20px;
       
    }
}
@media only screen and (max-width: 990px) {
    .fe-col{
        margin-left: 150px;
        margin-right: 3%;
    }
    .column{

        width: 40%;
        margin-right: 20px;
       
    }
}
@media only screen and (max-width: 768px) {
    .fe-col{
        margin-left: 100px;
        margin-right: 3%;
    }
    .column{

        width: 40%;
        margin-right: 20px;
       
    }
}

@media only screen and (max-width: 660px) {
    .fe-col{
        margin-left: 0px;
        margin-right: 0px;
    }
    .column{

        width: 100%;
        margin-right: 20px;
       
    }
}


.contact-icon{
    color:#0B4575;
    font-size: 30px;
}


.slogan{
    background-color: #0B4575;
    margin-top: -0px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fafafa;
    
  
}

 .worksIcon{
     font-size: 30px;
 }

.Hnotification-boxes .Hbox {
    margin-bottom: 30px;
    padding: 30px;
    color: #0B4575;
    text-align: center;
    background-color: #d9dcdf;
}

.slogan > h4{
    font-family: 'Julius Sans One', sans-serif;
}

.ImageConfig{
    height: 250px;
    width: 100%;
}
