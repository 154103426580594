.img_margin{
    margin-left: 30%;
}

.col_margin{
    margin-left: 15%;
}

@media only screen and (max-width: 790px) {
    .img_margin{
        margin-left: 0;
    }
}


@media only screen and (max-width: 1200px) {
    .img_margin{
        margin-left: 25%;
    }
}

@media only screen and (max-width: 790px) {
    .img_margin{
        margin-left: 0;
    }
}

@media only screen and (max-width: 1300px) {
    .col_margin{
        margin-left: 5%;
    }
}

@media only screen and (max-width: 950px) {
    .col_margin{
        margin-left: 0;
    }
}